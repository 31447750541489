<template>
  <div class="wrapper-main__content users">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom users-page-header">
        <VocabularyTitle :title="$t('sideBar.users')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
        <!--Search-->
        <div class="d-flex gap-3 justify-content-between mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

            <MultiSelect
              v-model="selectedAgency"
              label="name"
              track-by="id"
              :options="filterAgenciesList"
              class="multiselect-resize"
              :placeholder="$t('table.selectAgency')"
              @input="pageClick(1)"
            ></MultiSelect>
            <MultiSelect
              v-model="filterChannel"
              label="name"
              track-by="id"
              :options="filterChannelsList"
              :placeholder="$t('table.selectChannel')"
              class="multiselect-resize"
              @input="pageClick(1)"
            ></MultiSelect>
            <MultiSelect
              v-model="filterSaleshouse"
              label="name"
              track-by="id"
              :options="filterSaleshousesList"
              :placeholder="$t('table.selectSaleshouses')"
              class="multiselect-resize"
              @input="pageClick(1)"
            ></MultiSelect>
          </div>

          <search-input
            v-model="searchElements"
            :show-selector="true"
            :selected-search-type.sync="searchType"
            :options="searchFieldsList"
            @searchTable="pageClick(1)"
          />
        </div>
        <div class="d-flex gap-3 mt-3 w-100">
          <MultiSelect
            v-model="filterRole"
            label="name"
            track-by="id"
            :options="filterRolesList"
            class="multiselect-resize"
            :placeholder="$t('table.selectRole')"
            @input="pageClick(1)"
          ></MultiSelect>

          <b-form-checkbox v-if="canRestore" v-model="filterShowTrashed" class="mt-2" switch>
            {{ $t('table.showOnlyTrashed') }}
          </b-form-checkbox>
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <th scope="col">
              <div class="resizable">
                <div class="d-inline-block mr-3">
                  <sort-arrows :sort-string="sortString" :column="'created_at,'" @click="sortTable('created_at,', $event)" />
                  {{ $t('table.created') }}
                </div>
                <div class="d-inline-block">
                  <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                  {{ $t('sideBar.usersName') }}
                </div>
              </div>
            </th>
            <th scope="col">
              <div class="resizable">
                <sort-arrows :sort-string="sortString" :column="'email,'" @click="sortTable('email,', $event)" />
                {{ $t('sideBar.usersEmail') }}
              </div>
            </th>
            <th scope="col">
              <div class="resizable">
                <sort-arrows :sort-string="sortString" :column="'login,'" @click="sortTable('login,', $event)" />
                {{ $t('sideBar.usersLogin') }}
              </div>
            </th>
            <th scope="col">
              <div class="resizable">
                {{ $t('table.role') }}
              </div>
            </th>
            <!-- <th scope="col">
              <div class="resizable">
                <abbr :title="$t('table.agency') + '/' + $t('channelModal.channel') + '/' + $t('channelModal.salesHouses')">
                  {{isLocale === 'en' ? 'A/C/S' : 'А/К/Д'}}
                </abbr>
              </div>
            </th> -->
          </thead>
          <tbody>
            <tr v-if="usersStatus !== 'success'">
              <td colspan="5"><SpinnerLoader :loading="usersStatus" /></td>
            </tr>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>
                <div class="d-inline-flex">
                  <template v-if="!row.deleted_at">
                    <button v-if="canView" class="btn-icon" :title="canEdit ? $t('table.edit') : $t('table.details')" @click="showModalAddEdit('edit', row)">
                      <b-icon icon="pencil"></b-icon>
                    </button>
                    <button v-if="canDelete" class="btn-icon" :title="$t('table.deactivate')" @click="showModalDeleteUsers(row)">
                      <b-icon icon="person-x"></b-icon>
                    </button>
                  </template>
                  <button v-else-if="row.deleted_at && canRestore" class="btn-icon" :title="$t('table.restore')" @click="showModalRestoreUsers(row)">
                    <b-icon icon="person-plus"></b-icon>
                  </button>
                </div>
                {{ row.name }}
              </td>
              <td>{{ row.email }}</td>
              <td>{{ row.login }}</td>
              <td>{{ row.roles && row.roles.length > 0 ? row.roles[0].name : '' }}</td>
              <!-- <td>
                {{row.channel ? row.channel.name :
                  row.agency ? row.agency.name :
                  row.saleshouse ? row.saleshouse.name : ''}}
              </td> -->
            </tr>
            <tr v-if="usersStatus === 'success' && !tableData.length">
              <td colspan="5">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('user.create')" variant="primary" @click="showModalAddEdit('add')">
          {{ $t('table.addNewUsers') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <ModalUser
        :can-edit="canEdit"
        :agency_id="agency_id"
        :channel_id="channel_id"
        :saleshouse_id="saleshouse_id"
        :modal-name="modalName"
        :current-item="currentItem.data"
        :filter-agencies-list="filterAgenciesList"
        @updateUsers="paramsData"
        @clearModalProps="clearModalProps"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import ModalUser from '@/components/Users/ModalUser';
import SortArrows from '../components/SortArrows';

export default {
  name: 'Users',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, MultiSelect, SearchDropdown, SearchInput, ModalUser, SortArrows },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    agency_id: { type: [String, Number], default: undefined },
    channel_id: { type: [String, Number], default: undefined },
    saleshouse_id: { type: [String, Number], default: undefined },
    role_id: { type: [String, Number], default: undefined },
    show_deleted: { type: String, default: undefined },
    search_type: { type: String, default: undefined },
  },

  data() {
    return {
      currentItem: {
        data: {},
      },
      modalName: '',
      rowId: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      filterChannel: '',
      filterChannelsList: [],
      selectedAgency: '',
      filterAgenciesList: [],
      filterSaleshouse: '',
      filterSaleshousesList: [],
      filterRole: '',
      filterRolesList: [],
      filterShowTrashed: false,
      selectedPerPage: '100',
      loading: true,
      sortString: '-created_at,',
      searchType: 'name',
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      users: 'getUsers',
      usersStatus: 'getUsersStatus',
      agenciesList: 'getAgenciesList',
      channelsList: 'getChannelsList',
      salehousesList: 'getSalehousesList',
      roles: 'getRoles',
      isLocale: 'isLocale',
      //projects: 'getProjectsList',
      //advertisersList: 'getAdvertisersList',
      //brandsList: 'getBrandsList',
    }),

    canView: function () {
      return this.$checkPermissions('user.read');
    },
    canEdit: function () {
      return this.$checkPermissions('user.update');
    },
    canDelete: function () {
      return this.$checkPermissions('user.delete');
    },
    canRestore: function () {
      return this.$checkPermissions('user.restore');
    },

    searchFieldsList() {
      return [
        { value: 'name', text: this.$i18n.t('sideBar.usersName') },
        { value: 'login', text: this.$i18n.t('sideBar.usersLogin') },
        { value: 'email', text: 'Email' },
      ];
    },
  },
  watch: {
    users() {
      this.setTableData();
    },

    filterShowTrashed() {
      if (!this.loading) this.pageClick(1);
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.users') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getUsersInfo();
  },
  destroyed() {
    this.$store.commit('clearUsersList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_USERS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' && this.searchType === 'name' ? this.searchElements : null,
        'filter[login]': this.searchElements !== '' && this.searchType === 'login' ? this.searchElements : null,
        'filter[email]': this.searchElements !== '' && this.searchType === 'email' ? this.searchElements : null,
        'filter[channel_id]': this.filterChannel ? this.filterChannel.id : null,
        'filter[saleshouse_id]': this.filterSaleshouse ? this.filterSaleshouse.id : null,
        'filter[agency_id]': this.selectedAgency ? this.selectedAgency.id : null,
        'filter[role_id]': this.filterRole ? this.filterRole.id : null,
        'filter[trashed]': this.filterShowTrashed ? 'only' : 'no',
        sort: this.sortString,
        include: 'roles',
      });
      this.updateQuery();
    },
    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.users) {
        this.tableData = this.users.data;
        this.paginationData = this.users.pagination;
        if (this.filterPage > this.users.pagination.last_page) {
          this.filterPage = this.users.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    async getUsersInfo() {
      await Promise.all([
        this.$store.dispatch('GET_AGENCIES', { per_page: 1000 }),
        this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }),
        this.$store.dispatch('GET_SUBCHANNELS', { per_page: 1000 }),
        this.$store.dispatch('GET_SALEHOUSES', { per_page: 1000 }),
        this.$store.dispatch('GET_ROLES', { per_page: 1000 }),
      ]);
      this.filterAgenciesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.agenciesList];
      this.filterChannelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channelsList];
      this.filterSaleshousesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.salehousesList];
      this.filterRolesList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.roles];

      await this.restoreFromQuery('users');
      this.paramsData();
      this.loading = false;
    },

    showModalAddEdit(type, prop) {
      this.$set(this.currentItem, 'data', prop);
      //this.currentItem = prop;
      this.modalName = type;
      this.$bvModal.show('add-edit-users');
    },
    showModalDeleteUsers(prop) {
      //this.currentItem = prop;
      this.$set(this.currentItem, 'data', prop);
      this.$bvModal.show('delete-users-confirm');
    },
    showModalRestoreUsers(prop) {
      //this.currentItem = prop;
      this.$set(this.currentItem, 'data', prop);
      this.$bvModal.show('restore-users-confirm');
    },

    clearModalProps() {
      this.modalName = '';
      this.$set(this.currentItem, 'data', {});
    },
  },
};
</script>

<style lang="sass" scoped>
.users .users-page-header
  .multiselect.multiselect-resize
    width: 270px

.users .vocabulary-table
  max-height: calc(100vh - 205px)

@media (max-width: 1540px)
  .users .users-page-header
    .multiselect.multiselect-resize
      width: 200px

input#input-password::-ms-reveal,
input#input-password::-ms-clear
  display: none

div.table .table-body-title
  overflow: hidden
  text-overflow: ellipsis

.w-20
  width: 20%
  min-width: 20%

$w-name: 200px
$w-email: 100px
$w-login: 150px
$w-role: 150px
//$w-entity: 150px

table.table.table-hover
  thead
    th:first-child
      min-width: $w-name
    th:nth-child(2)
      min-width: $w-email
    th:nth-child(3)
      min-width: $w-login
    th:nth-child(4)
      min-width: $w-role
    // th:nth-child(5)
    //   min-width: $w-entity
  tbody tr
    td:first-child
      max-width: $w-name
      width: $w-name
    td:nth-child(2)
      max-width: $w-email
      width: $w-email
    td:nth-child(3)
      max-width: $w-login
      width: $w-login
    td:nth-child(4)
      max-width: $w-role
      width: $w-role
    // td:nth-child(5)
    //   max-width: $w-entity
    //   width: $w-entity
</style>
